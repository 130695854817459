import { InfoFill } from "akar-icons";
import toast from "react-hot-toast";

InfoFill;

const InfoToast = (props) => {
  const {
    title = "Bu bilgi neden gerekli?",
    message,
    duration = 6000,
    id,
  } = props;

  return (
    <div
      onClick={() => {
        toast(message, {
          duration,
          id,
        });
      }}
      className="text-xs whitespace-nowrap  flex gap-2 mt-3 text-neutral-600 mb-9 cursor-pointer max-w-min"
    >
      <InfoFill size={14} />
      {title}
    </div>
  );
};

export default InfoToast;
