const SubmitButton = (props) => {
  const { handler, checking = false, disable, type = "button" } = props;

  return (
    <button
      onClick={handler}
      disabled={!disable}
      type={type}
      className={
        (!disable || checking
          ? "bg-neutral-500 cursor-not-allowed text-neutral-50 "
          : "bg-primaryLight cursor-pointer text-neutral-800 hover:bg-opacity-80 ") +
        "py-3 px-6 rounded-md transition-all duration-100 ease-in inline-flex items-center justify-center whitespace-nowrap w-full"
      }
    >
      {checking && (
        <svg
          className="animate-spin -ml-1 mr-3 h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {!checking && props.children}
    </button>
  );
};

export default SubmitButton;
