import axios from "axios";

const setCitySelect = async (country, handler) => {
  const { data } = await axios.get(
    `https://api.countrystatecity.in/v1/countries/${country}/states`,
    {
      headers: {
        "X-CSCAPI-KEY":
          "dTEwM1lWUzBWVzJoZFhwaDVybjZjeXBaUnp5aXFobmpIS2xUOEtIVw==",
      },
    }
  );
  const cityList = await data
    .map((city) => {
      return {
        value: city.iso2,
        label: city.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  handler(cityList);
};

export default setCitySelect;
