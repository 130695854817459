import { productRequestForGuestPopupAtom } from "../../../atoms/popupAtoms";
import { countries } from "../../../constants/selectLists";
import errorToast from "../../../libs/errorToast";
import getTranslatedText from "../../../libs/getTranslatedText";
import setCitySelect from "../../../libs/setCitySelect";
import InfoToast from "../../modules/toast/InfoToast";
import SubmitButton from "../buttons/SubmitButton";
import Input from "../form/Input";
import MultilineInput from "../form/MultilineInput";
import SelectInput from "../form/Select";
import TelephoneInput from "../form/TelephoneInput";
import { createClient } from "@supabase/supabase-js";
import { CircleX } from "akar-icons";
import { atom, useAtom } from "jotai";
import { useRouter } from "next/dist/client/router";
import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";

const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
);

const mountedAtom = atom(false);

const ProductRequestPopupForGuest = ({ user, loading }) => {
  const [request, setRequest] = useAtom(productRequestForGuestPopupAtom);
  const [mounted, setMounted] = useAtom(mountedAtom);
  const [handling, setHandling] = useState(false);
  const { locale } = useRouter();
  const [formValid, setFormValid] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [formValues, setFormValues] = useState({
    city: {
      value: null,
      label:
        user?.city ||
        getTranslatedText(
          locale,
          "Lütfen bir şehir seç",
          "onboarding.form.inputs.city.placeholder"
        ),
    },
    country: {
      value: null,
      label: getTranslatedText(
        locale,
        "Lütfen bir ülke seç",
        "onboarding.form.inputs.country.placeholder"
      ),
    },
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    message: "",
  });

  // Set initial values of country
  useEffect(() => {
    if (formValues.country.value === null) {
      setFormValues((prev) => {
        return {
          ...prev,
          country: {
            value:
              locale === "tr"
                ? "TR"
                : locale === "ru"
                ? "RU"
                : locale === "es"
                ? "ES"
                : "US",
            label: countries[locale].find(
              (c) => c.value === (locale === "en" ? "US" : locale.toUpperCase())
            ).label,
          },
        };
      });
    }
  }, [setFormValues, locale, formValues.country]);

  // Set list values of city based on country
  useEffect(() => {
    if (formValues.country.value) {
      setCitySelect(formValues.country.value, setCityList);
    }
  }, [formValues.country]);

  // Handling form submit
  const formSubmitHandler = async () => {
    setHandling(true);
    try {
      const response = await supabase.from("product_request_guests").insert({
        city: formValues.city.label,
        country: JSON.stringify({
          tr: countries["tr"].find((c) => c.value === formValues.country.value)
            .label,
          en: countries["en"].find((c) => c.value === formValues.country.value)
            .label,
          ru: countries["ru"].find((c) => c.value === formValues.country.value)
            .label,
          es: countries["es"].find((c) => c.value === formValues.country.value)
            .label,
        }),
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        mobile: formValues.mobile,
        email: formValues.email,
        company: request.companyId,
        product: request.productId,
        type: request.type,
        message: formValues.message,
        productUrl: window.location.href,
      });

      toast.success(
        getTranslatedText(
          locale,
          "Talebin başarılı bir şekilde oluşturuldu.",
          "product.requestPopup.successToast"
        )
      );
      setRequest({
        show: true,
        newRequest: true,
        alreadyRequested: false,
        request: response.data?.[0],
      });
      setHandling(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      errorToast(locale);
      setHandling(false);
    }
  };

  // Close popup on ESC
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        setRequest((prev) => {
          return { ...prev, show: false };
        });
      }
    },
    [setRequest]
  );

  // Handling mounted state
  useEffect(() => {
    if (mounted) {
      document.addEventListener("keyup", escFunction);
    } else {
      setMounted(true);
    }
  }, [setMounted, escFunction, mounted]);

  // Handling form validation
  useEffect(() => {
    // Request Type
    if (request.type === "sales") {
      // Turkish Visitors
      if (locale === "tr") {
        if (
          formValues.firstName ==="" ||
          formValues.lastName ==="" ||
          formValues.email ==="" ||
          formValues.mobile ==="" ||
          formValues.message ===""
        ) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }
      // International Visitors
      else {
        if (
          formValues.contact_preference === "mobile" &&
          (!formValues.mobile || !formValues.country.value)
        ) {
          setFormValid(false);
        } else if (
          formValues.contact_preference === "email" &&
          !formValues.country.value
        ) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }
    } else {
      // Turkish Visitors
      if (locale === "tr") {
        if (
          formValues.contact_preference === "mobile" &&
          (!formValues.mobile ||
            !formValues.city.value ||
            !formValues.message.length > 5)
        ) {
          setFormValid(false);
        } else if (
          formValues.contact_preference === "email" &&
          (!formValues.city.value || !formValues.message.length > 5)
        ) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }
      // International Visitors
      else {
        if (
          formValues.contact_preference === "mobile" &&
          (!formValues.mobile ||
            !formValues.country.value ||
            !formValues.message.length > 5)
        ) {
          setFormValid(false);
        } else if (
          formValues.contact_preference === "email" &&
          (!formValues.country.value || !formValues.message.length > 5)
        ) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }
    }
  }, [formValues, request.type, locale]);

  if (request.show && mounted && !loading) {
    return ReactDOM.createPortal(
      <div className="fixed z-50 flex items-center justify-center min-w-full min-h-screen px-5 bg-neutral-900 bg-opacity-80">
        <div className="relative z-20 rounded-md bg-neutral-50 overflow-scroll max-h-96 sm:max-h-none">
          {!request.alreadyRequested && request.newRequest && (
            /* Request Preview - New Request */
            <div>
              <div className="flex items-center justify-between px-4 py-4 border-b sm:px-8 border-b-gray-300">
                <div>
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold">
                      {getTranslatedText(
                        locale,
                        "Talep",
                        "product.requestPopup.alreadyRequested.request"
                      )}
                    </h2>
                    <div className="flex items-center justify-center gap-2 px-3 py-2 rounded-md bg-emerald-100">
                      <div className="w-2 h-2 rounded-full bg-emerald-500"></div>
                      <span className="text-xs text-emerald-500">
                        {getTranslatedText(
                          locale,
                          "Yeni",
                          "product.requestPopup.newRequest.new"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setRequest((prev) => {
                      return { ...prev, show: false };
                    });
                  }}
                  className="self-start transition-colors duration-200 ease-out cursor-pointer text-neutral-400 hover:text-neutral-800"
                >
                  <CircleX size={18} />
                </div>
              </div>
              <div className="px-4 py-6 border-b sm:px-8 border-b-gray-300">
                <h3 className="text-xl font-medium">
                  {getTranslatedText(
                    locale,
                    "Talebin oluşturuldu.",
                    "product.requestPopup.newRequest.title"
                  )}
                </h3>
                <p className="max-w-sm mt-1 text-sm text-neutral-500">
                  {getTranslatedText(
                    locale,
                    "Talebiniz başarıyla oluşturuldu. En kısa sürede size geri dönüş yapılacaktır.",
                    "product.requestPopup.newRequest.description"
                  )}
                </p>
                <div className="flex items-center gap-2 mt-4">
                  <span>
                    {getTranslatedText(
                      locale,
                      "Dönüş Süresi",
                      "product.requestPopup.newRequest.waitingTime"
                    )}
                    :
                  </span>
                  <span className="px-3 py-2 text-sm bg-gray-100 rounded-md text-neutral-600">
                    {getTranslatedText(
                      locale,
                      "1-5 İş Günü",
                      "product.requestPopup.newRequest.waitingTimeText"
                    )}
                  </span>
                </div>
                <div></div>
              </div>
            </div>
          )}
          {
            /*  Create a New Request */
            !request.alreadyRequested && !request.newRequest && (
              <>
                <div className="flex items-center justify-between px-4 py-4 border-b sm:px-8 border-b-gray-300">
                  <h2>
                    {getTranslatedText(
                      locale,
                      "Talebini iletmek için şunları doldur.",
                      "product.requestPopup.form.title"
                    )}
                  </h2>
                  <div
                    onClick={() => {
                      setRequest((prev) => {
                        return { ...prev, show: false };
                      });
                    }}
                    className="transition-colors duration-200 ease-out cursor-pointer text-neutral-400 hover:text-neutral-800"
                  >
                    <CircleX size={18} />
                  </div>
                </div>
                <div className="px-4 py-10 bg-white sm:px-8">
                  <div className="flex flex-col">
                    <div className="grid gap-10 sm:grid-cols-2">
                      <>
                        <SelectInput
                          options={countries[locale]}
                          placeholder={getTranslatedText(
                            locale,
                            "Lütfen bir ülke seç",
                            "onboarding.form.inputs.country.placeholder"
                          )}
                          value={formValues.country}
                          label={getTranslatedText(
                            locale,
                            "Ülke",
                            "onboarding.form.inputs.country.label"
                          )}
                          handler={(value) => {
                            setFormValues((prev) => {
                              return {
                                ...prev,
                                country: value,
                              };
                            });
                          }}
                        ></SelectInput>
                        <SelectInput
                          id="onboarding-city"
                          options={cityList}
                          placeholder={getTranslatedText(
                            locale,
                            "Lütfen bir şehir seç",
                            "onboarding.form.inputs.city.placeholder"
                          )}
                          value={formValues.city}
                          label={getTranslatedText(
                            locale,
                            "Şehir",
                            "onboarding.form.inputs.city.label"
                          )}
                          handler={(value) => {
                            setFormValues((prev) => {
                              return {
                                ...prev,
                                city: value,
                              };
                            });
                          }}
                        ></SelectInput>
                      </>
                    </div>
                    <div>
                      <InfoToast
                        id="country"
                        title={getTranslatedText(
                          locale,
                          "Bu bilgi neden gerekli?",
                          "onboarding.form.toast.second.title"
                        )}
                        message={getTranslatedText(
                          locale,
                          "Bu bilgi hem sana bölgesel özelleştirme yapmamıza hem de taleplerini daha hızlı çözmemize yarıyor.",
                          "onboarding.form.toast.first.message"
                        )}
                      />
                    </div>

                    <>
                      <div className="mb-8 flex gap-4">
                        <Input
                          label={getTranslatedText(
                            locale,
                            "Ad *",
                            "product.requestPopup.form.nameTitle"
                          )}
                          id="firstName"
                          name="firstName"
                          placeholder={getTranslatedText(
                            locale,
                            "Ad *",
                            "product.requestPopup.form.messagePlaceholder"
                          )}
                          value={formValues.firstName}
                          handler={(e) => {
                            setFormValues((prev) => {
                              return {
                                ...prev,
                                firstName: e.target.value,
                              };
                            });
                          }}
                        />
                        <Input
                          label={getTranslatedText(
                            locale,
                            "Soyad *",
                            "product.requestPopup.form.lastNameTitle"
                          )}
                          id="lastName"
                          name="lastName"
                          placeholder={getTranslatedText(
                            locale,
                            "Soyad *",
                            "product.requestPopup.form.lastNamePlaceholder"
                          )}
                          value={formValues.lastName}
                          handler={(e) => {
                            setFormValues((prev) => {
                              return {
                                ...prev,
                                lastName: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="mb-8 w-1/2">
                        <Input
                          label={getTranslatedText(
                            locale,
                            "Email *",
                            "product.requestPopup.form.emailTitle"
                          )}
                          id="email"
                          name="email"
                          placeholder={getTranslatedText(
                            locale,
                            "E-mail adresin *",
                            "product.requestPopup.form.messagePlaceholder"
                          )}
                          value={formValues.email}
                          handler={(e) => {
                            setFormValues((prev) => {
                              return {
                                ...prev,
                                email: e.target.value,
                              };
                            });
                          }}
                        />
                        </div>

                        <div className="w-1/2">
                          <TelephoneInput
                            handler={(value) => {
                              setFormValues((prev) => {
                                return {
                                  ...prev,
                                  mobile: value,
                                };
                              });
                            }}
                            required={true}
                            country={formValues.country.value}
                            value={formValues.mobile}
                            locale={locale}
                          />
                          <InfoToast
                            id="mobile"
                            title={getTranslatedText(
                              locale,
                              "Bu bilgi neden gerekli?",
                              "onboarding.form.toast.second.title"
                            )}
                            message={getTranslatedText(
                              locale,
                              "Telefon numaranı, eğer sen tercih edersen taleplerine daha hızlı geri dönüş yapılması için kullanacağız. Bunun dışında hiçbir şekilde iletişim kurmayacağımıza emin olabilirsin.",
                              "onboarding.form.toast.second.message"
                            )}
                          />
                        </div>
                      </div>
                    </>

                    <div className="">
                      <MultilineInput
                        label={getTranslatedText(
                          locale,
                          "Mesaj *",
                          "product.requestPopup.form.messageTitle"
                        )}
                        id="talep-mesaj"
                        placeholder={getTranslatedText(
                          locale,
                          "Adet, tür vb gibi talebinle ilgili detaylı bilgiyi buraya yaz.",
                          "product.requestPopup.form.messagePlaceholder"
                        )}
                        value={formValues.message}
                        handler={(e) => {
                          setFormValues((prev) => {
                            return {
                              ...prev,
                              message: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>

                    <div className="mt-9">
                      <SubmitButton
                        handler={formSubmitHandler}
                        checking={handling}
                        size="large"
                        full={true}
                        disable={formValid}
                      >
                        {getTranslatedText(
                          locale,
                          "Gönder",
                          "product.requestPopup.form.button"
                        )}
                      </SubmitButton>
                    </div>
                  </div>
                </div>
                <div className="px-8 py-4 text-xs border-t border-t-gray-300">
                  {getTranslatedText(
                    locale,
                    "Gizliliğinize önem veriyoruz. Detaylar için aydınlatma metnini okuyabilirsiniz.",
                    "loginPopup.privacyText"
                  )}
                </div>
              </>
            )
          }
        </div>
        <div
          onClick={() => {
            setRequest((prev) => {
              return { ...prev, show: false };
            });
          }}
          className="absolute top-0 bottom-0 left-0 right-0 z-10"
        ></div>
      </div>,
      document.getElementById("full-modal-root")
    );
  } else {
    return null;
  }
};

export default ProductRequestPopupForGuest;
