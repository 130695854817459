import dynamic from "next/dynamic";
import { useState } from "react";

const Mention = dynamic(() => import("akar-icons").then((mod) => mod.Mention));
const Check = dynamic(() => import("akar-icons").then((mod) => mod.Check));
const EyeOpen = dynamic(() => import("akar-icons").then((mod) => mod.EyeOpen));
const EyeSlashed = dynamic(() =>
  import("akar-icons").then((mod) => mod.EyeSlashed)
);

const Input = (props) => {
  const {
    id,
    name,
    label,
    placeholder,
    type = "text",
    valid = false,
    error = "",
    touched,
    value,
    handler,
  } = props;
  const [visiblePass, setVisiblePass] = useState(false);
  const inputType = type;

  return (
    <div className="relative w-full">
      <label
        className="absolute px-3 leading-none bg-white left-2 -top-3"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        name={name}
        value={value}
        onChange={(e) => {
          handler(e);
        }}
        className={
          (touched && !valid
            ? "border-red-500 focus:outline-none"
            : touched && valid
            ? "border-primaryLight ring-1 ring-primaryLight focus:outline-none"
            : "border-gray-300 focus:outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight") +
          " px-4 py-3 rounded-md w-full border"
        }
        id={id}
        type={visiblePass ? "text" : type}
        placeholder={placeholder}
      />
      {!valid && touched && (
        <div className="text-xs text-red-600 mt-2 max-w-[350px]">{error}</div>
      )}
      {(inputType === "email") & !valid ? (
        <div className="absolute right-4 top-4">
          <Mention size={18} />
        </div>
      ) : valid ? (
        <div className="absolute right-4 top-4 text-primaryLight">
          <Check size={18} />
        </div>
      ) : null}
      {(inputType === "password") & !valid & !visiblePass ? (
        <div
          onClick={() => {
            setVisiblePass((prevState) => !prevState);
          }}
          className="absolute cursor-pointer right-4 top-4"
        >
          <EyeSlashed size={18} />
        </div>
      ) : null}
      {(inputType === "password") & !valid & visiblePass ? (
        <div
          onClick={() => {
            setVisiblePass((prevState) => !prevState);
          }}
          className="absolute cursor-pointer right-4 top-4"
        >
          <EyeOpen size={18} />
        </div>
      ) : null}
    </div>
  );
};

export default Input;
