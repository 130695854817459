const MultilineInput = ({ label, value, handler, id, placeholder }) => {
  return (
    <div className="flex flex-col gap-2">
      <label className="font-medium" htmlFor={id}>
        {label}
      </label>
      <textarea
        className="w-full py-2 px-3 border border-gray-200 rounded-md focus:outline-primaryLight focus:border-primaryLight"
        name={label}
        id={id}
        cols="30"
        value={value}
        placeholder={placeholder}
        onChange={handler}
        rows="5"
      ></textarea>
    </div>
  );
};

export default MultilineInput;
