import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import ru from "react-phone-input-2/lang/ru.json";
import tr from "react-phone-input-2/lang/tr.json";
import "react-phone-input-2/lib/style.css";

const TelephoneInput = ({ locale = "tr", handler, value, country, required }) => {
  const locales = {
    tr,
    en: undefined,
    ru,
    es,
  };

  const label = {
    en: "Phone",
    tr: "Telefon",
    ru: "Телефон",
    es: "Teléfono",
  };

  return (
    <div className="relative">
      <div className="absolute z-30 px-3 leading-none bg-white left-2 -top-3">
        {label[locale]} {required && <span>*</span>}
      </div>
      <PhoneInput
        localization={locales[locale]}
        country={country?.toLowerCase() || "tr"}
        onChange={handler}
        value={value}
        containerClass="tel-input-container"
        inputClass="tel-input"
        dropdownClass="tel-input-dropdown"
        buttonClass="tel-input-button"
      />
    </div>
  );
};

export default TelephoneInput;
