import Select from "react-select";

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: "6px",
    colors: {
      ...theme.colors,
      neutral20: "#D1D5DB",
      primary: "#66E78E",
      primary25: "#efefef",
      primary75: "#efefef",
      primary50: "#efefef",
    },
  };
};

const notFoundText = {
  en: "No result was found.",
  tr: "Sonuç bulunamadı.",
  ru: "Результат не найден.",
  es: "No se encontró ningún resultado.",
};

const SelectInput = (props) => {
  const {
    options,
    label,
    handler,
    placeholder,
    locale = "tr",
    value,
    isMulti = false,
    size = "large",
    id,
  } = props;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      padding: size === "large" ? "6px 6px" : "2px 4px",
      borderRadius: "6px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "#9CA3AF",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "100000",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "6px",
      backgroundColor: "#D1FAE5",
      color: "#007C27",
      padding: "0px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#007C27",
      margin: "2px 4px",
    }),
    multiValueRemove: (provided) => {
      return {
        ...provided,
        borderRadius: "4px",
        height: "auto",
        backgroundColor: "none",
        color: "#007C27",
      };
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
        fontSize: "14px",
        fontWeight: "light",
      };
    },
  };

  return (
    <div className="relative w-full">
      <div className="absolute z-10 px-3 leading-none bg-white left-2 -top-3">
        {label}
      </div>
      <Select
        id={id}
        instanceId={id}
        theme={customTheme}
        styles={customStyles}
        options={options}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={handler}
        isSearchable
        isMulti={isMulti}
        noOptionsMessage={() => notFoundText[locale]}
      ></Select>
    </div>
  );
};

export default SelectInput;
